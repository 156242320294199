import React from 'react';
import {useTranslation} from 'react-i18next';
import { Grid} from '@mui/material';
import {RoadCard} from 'components';


function RoadCardList({contents, onSelectionRoad}) {
	const {t} = useTranslation();
	return contents.map((notification, i) => {
		const {id, name, description} = notification
		return (
			<Grid item xs={10} md={4} key={i}>
				<RoadCard onClick={onSelectionRoad} title={name} id={id} />
			</Grid>
		)
	})
}

export default RoadCardList;