import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
	alpha,
	Box,
	IconButton, Link,
	Toolbar,
	Typography,
} from '@mui/material';
import {useAuthContext} from '../../contexts/auth';
import {TranslateLink} from 'components';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import theme from '../../theme';

const myStyles = {
	toolbar:{
		background: theme.palette.primary.light,
	},
	link: {
		fontSize:'1rem',
		margin: theme.spacing(1, 1.5),
		[theme.breakpoints.down("sm")]: {
			fontSize:'1rem',
		},
		'&:hover': {
			color: alpha(theme.palette.common.white, 0.75),
		},
	},
};

function Header(props) {
	const {t} = useTranslation();
	const { user, setUser, handleLogout } = useAuthContext()

	return (
		<>
			<Toolbar sx={myStyles.toolbar}>
				<Typography variant="h4" sx={{ display: { xs: 'none', sm: 'block' } }}>ECG</Typography>
				<Box sx={{ flexGrow: 1 }} />
				<Box sx={{ display: { xs: 'flex' } }}>
					<Link variant="button" color="textPrimary" href='/dashboard' sx={myStyles.link}>
						{t("menu.Notifications")}
					</Link>
					<Link variant="button" color="textPrimary" href='/clients' sx={myStyles.link}>
						{t("menu.Clients")}
					</Link>
					{/*<Link variant="button" color="textPrimary" href='/routes' sx={myStyles.link}>*/}
					{/*	{t("menu.Routes")}*/}
					{/*</Link>*/}
					<Link variant="button" color="textPrimary" href='/logs' sx={myStyles.link}>
						{t("menu.logs")}
					</Link>
					<TranslateLink />
					<IconButton size="large" aria-label="show" color="inherit" onClick={handleLogout}>
						<ExitToAppIcon  />
					</IconButton>
				</Box>
			</Toolbar>

		</>
	);
}

export default Header;