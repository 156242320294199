import axios from 'axios';

class ApiClient {
	constructor(remoteHostUrl) {
		this.remoteHostUrl = remoteHostUrl;
		this.tokenName = 'ecgToken';
		this.token = null;
	}

	setToken(token) {
		this.token = token;
		localStorage.setItem(this.tokenName, token);
	}

	async request({ endpoint, method = 'GET', data = {} }) {
		const url = `${this.remoteHostUrl}/${endpoint}`;
		const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			Authorization: this.token ? `Bearer ${this.token}` : '',
		};

		try {
			const res = await axios({ url, method, data, headers });
			return { data: res.data, error: null };
		} catch (error) {
			//TODO - Retirer quand on va être live
			console.error('APIclient.makeRequest.error:');
			console.error({ errorResponse: error.response });
			const message = error?.response?.data?.error?.message;
			return { data: null, error: message || String(error) };
		}
	}

	async fetchAccountConfirmed(token) {
		return await this.request({
			endpoint: `auth/confirmaccount/${token}`,
			method: `GET`,
		});
	}

	async fetchUserFromToken() {
		return await this.request({ endpoint: `auth/me`, method: `GET` });
	}

	async fetchAllNotifications() {
		return await this.request({
			endpoint: `notifs/fetchnotifications/`,
			method: `GET`,
		});
	}

	async fetchAllRoutes() {
		return await this.request({
			endpoint: `roads/fetchallroads/`,
			method: `GET`,
		});
	}

	async loginUser(credentials) {
		return await this.request({
			endpoint: `auth/login`,
			method: `POST`,
			data: credentials,
		});
	}

	async logoutUser() {
		this.setToken(null);
		localStorage.setItem(this.tokenName, '');
	}

	async ResendConfirmEmail(email) {
		return await this.request({
			endpoint: `auth/resendconfirmemail`,
			method: `POST`,
			data: { email },
		});
	}

	async sendNotifications(idNotif, idRoute) {
		return await this.request({
			endpoint: `roads/sendNotifications`,
			method: `POST`,
			data: { idNotif, idRoute },
		});
	}

    async addNewClient(form) {
		return await this.request({
			endpoint: `inscription/addnewclient`,
			method: `POST`,
			data: { form },
		});
    }

	async fetAllClients() {
		return await this.request({
			endpoint: `clients/fetchallclients/`,
			method: `GET`,
		});
	}

	async updateClientRoad(clientId, roadId) {
		return await this.request({
			endpoint: `clients/updateclientroad`,
			method: `POST`,
			data: { clientId, roadId },
		});
	}

	async fetAllLogs() {
		return await this.request({
			endpoint: `logs/fetchalllogs/`,
			method: `GET`,
		});
	}
}

export default new ApiClient(
	'https://ecgnotifs.ca/api'
	// 'http://localhost:5001/api'
);
