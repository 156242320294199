import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import './lang/i18nextConf';
import {ThemeProvider} from "@emotion/react";
import {CssBaseline} from "@mui/material";
import theme from './theme'

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
            <App />
    </ThemeProvider>,
  document.getElementById('root')
);
