import React, {useEffect} from 'react';
import theme from "../../theme";
import {useTranslation} from "react-i18next";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {BaseTextField} from "../textfield/StyledTextField";
import apiClient from "../../services/apiClient";

const myStyles = {
    modal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        overflowY:'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height:'100%'
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    },
    icons:{
        fontSize:'35px',
        margin:'0 5px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.main
        }
    }
};

function EditRoadModal({open, handleClose, handleEditRoadCompleted, client, roads}) {
    const {t} = useTranslation()
    const [road, setRoad] = React.useState(9999);
    const [error, setError] = React.useState({});

    const handleChange = (e) => {
        setRoad(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        apiClient.updateClientRoad(client.id, road).then(() => {
            handleEditRoadCompleted();
        }).catch(err => {
            setError({msg: err.response.data.message})
        })
    }

    useEffect(() => {
        setRoad(client?.route)
    }, [client])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={myStyles.modal}>
                <Grid container alignItems="center">
                    {
                        error?.msg && (
                            <>
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>{t('app.Error')}</AlertTitle>
                                        <Typography>{t(error.msg)}</Typography>
                                    </Alert>
                                </Grid>
                            </>
                        )
                    }
                    <Grid item xs={12} md={10}>
                        <h2>{t('editClientRoad.Title')}</h2>
                    </Grid>
                    <Grid item xs={12} md={2} sx={{textAlign: 'right'}}>
                        <HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{pt: 2}}>
                    <Grid item xs={12}>
                        <span>{client.firstName} {client.lastName}</span>
                    </Grid>
                    <Grid item xs={12} sx={{pb:3}}>
                        <span>{client.address}</span>
                    </Grid>
                    <Grid item xs={12} >
                        <FormControl fullWidth autoFocus required>
                            <InputLabel htmlFor="road">{t('clients.Road')}</InputLabel>
                            <Select
                                value={road}
                                onChange={handleChange}
                                label={t('clients.Road')}
                                inputProps={{
                                    name: 'name',
                                    id: 'id',
                                }}
                            >
                                {roads.map((r, index) => (
                                    <MenuItem key={index} value={r.id}>{r.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{pt: 2}}>
                    <Grid item xs={12} lg={3}>
                        <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleClose}>
                            {t('app.Cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} lg={6}/>
                    <Grid item xs={12} lg={3}>
                        <Button fullWidth variant="contained" color="primary" size="large" type="submit" onClick={handleSubmit}>
                            {t('app.Save')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default EditRoadModal;