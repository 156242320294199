import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid} from '@mui/material';
import {NotificationCardList, Title, ToastMsg} from 'components';
import apiClient from '../../../services/apiClient';

function Dashboard(props) {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(true);
	const [notifications, setNotifications] = useState([])

	useEffect(() => {
		const fetchAllNotifications = async() => {
			const {data, error} = await apiClient.fetchAllNotifications()
			if (data){
				setNotifications(data.notifications)
			}
			if (error){
				setErrors({msg: error})
			}
			setIsFetching(false)
		}
		fetchAllNotifications()
	}, []);

	const handleRoute = (id) => {
		navigate(`/route/${id}`)
	}

	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg &&
				<ToastMsg open={true} type="error" message={t('apps.Error')}/>}
			{!isFetching && (
				<>
					<Grid container>
						<Grid item xs={12}>
							<Title title='notification.Title' subtitle=''/>
						</Grid>
					</Grid>

					<Grid container alignItems="center" justifyContent="space-between" spacing={3} sx={{paddingTop:2}}>
						<NotificationCardList contents={notifications} onSelectionNotification={handleRoute}/>
					</Grid>
				</>
			)}

		</>
	);
}

export default Dashboard;