import React, {useState} from 'react';
import {
    Alert, AlertTitle,
    Button,
    Checkbox, CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Typography
} from "@mui/material";
import loginImg from "../../../asset/images/LogoECG.png";
import {BaseTextField} from "../../textfield/StyledTextField";
import {useTranslation} from "react-i18next";
import MapAutoComplete from "../../textfield/MapAutoComplete";
import NumberFormat from "react-number-format";
import apiClient from "../../../services/apiClient";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="(###) ###-####" allowEmptyFormatting mask="_"
        />
    );
});

function Inscription(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [code, setCode] = useState('');
    const [form, setForm] = useState({firstName:'',lastName:'', address:'',local:'', cellPhone:'', email:'', isEmail:false, isSMS:false});
    const [isProcessing, setIsProcessing] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleChange = (element) => {
        setIsSuccess(false)
        setErrors({msg:null})
        if ( errors.hasOwnProperty(element.target.name) ) {
            setErrors((e) => ({ ...e, [element.target.name]: null }))
        }
        if (element.target.name === "email") {
            if (element.target.value.indexOf("@") === -1) {
                setErrors((e) => ({ ...e, email: "app.ValidEmail" }))
            } else {
                setErrors((e) => ({ ...e, email: null }))
            }
        }
        setForm({ ...form, [element.target.name]: element.target.value });
    }

    const handleChangeCode = (element) => {
        setCode(element.target.value);
    }

    const handleChangeAutoComplete = (value) => {
        setErrors((e) => ({ ...e, address: null }))
        setForm({...form, address : (value?.description ? value.description : '' )})
    }

    const handleMultiCheckBox = (event) => {
        setForm({...form, [event.target.name]: event.target.checked})
    };

    const submitHandler = async () => {
        const requiredFields = [
            'firstName',
            'lastName',
            'address'];
        let hasError = false;
        setIsProcessing(true)
        setErrors((e) => ({...e, form: null}))
        setErrors((e) => ({...e, msg: null}))

        Object.entries(form).map(field => {
            if (requiredFields.indexOf(field[0]) !== -1 && field[1] === '') {
                setErrors(
                    (e) => ({...e, [field[0]]: "app.RequiredFields"}))
                hasError = true
            }
        })

        if (form.isSMS && form.cellPhone === '') {
            setErrors((e) => ({...e, cellPhone: "app.RequiredFields"}))
            hasError = true
        }

        if (form.isEmail && form.email === '') {
            setErrors((e) => ({...e, email: "app.RequiredFields"}))
            hasError = true
        }

        if (!hasError) {
            const {data, error} = await apiClient.addNewClient(form)
            if (data) {
                setForm({ firstName: '', lastName: '', address: '', local: '', cellPhone: '', email: '', isEmail: false, isSMS: false })
                setIsSuccess(true)
            }
            if (error) {
                setErrors({msg: error})
            }
            setIsProcessing(false)
        }
        setIsProcessing(false)
    }
    return (
        <Container maxWidth='lg'>
            <Grid
                container
                alignItems='center'
                justifyContent='space-between'>
                <Grid item xs={12} sx={{pt:5}}>
                    <div className='inscription__image'>
                        <img src={loginImg} alt='ECGNotifs' width='250px' />
                    </div>
                </Grid>
                <Grid item xs={12} sx={{pt:5}}>
                    <Typography variant='h2'>
                        Inscription
                    </Typography>
                    <Typography variant='h4'>
                        Nous vous avons fourni un code. Ce code vous permettra de remplir le formulaire et de recevoir les notifications soit par courriel et/ou SMS
                    </Typography>
                    <Typography variant='h6'>
                        Si vous n'avez pas reçu de code. Communiquer avec votre propriétaire ou avec ECG
                    </Typography>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <BaseTextField
                            label={t('inscription.Code')}
                            required
                            fullWidth
                            autoFocus
                            variant="outlined"
                            name="code"
                            onChange={handleChangeCode}
                            value={code}
                            error={errors.code && true}
                            helperText={errors.code && t(errors.code) }
                        />
                    </Grid>
                    {
                        isSuccess &&(
                            <>
                                <Grid item xs={12}>
                                    <Alert severity="success">
                                        <AlertTitle>{t('inscription.SuccessTitle')}</AlertTitle>
                                        <Typography>{t('inscription.SuccessMsg')}</Typography>
                                    </Alert>
                                </Grid>
                            </>
                        )
                    }
                    {
                        errors.msg && (
                            <>
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>{t('inscription.ErrorTitle')}</AlertTitle>
                                        <Typography>{t(errors.msg)}</Typography>
                                    </Alert>
                                </Grid>
                            </>
                        )
                    }
                    {
                        code === 'hiver2324' && (
                            <>

                                <Grid item xs={12} md={6}>
                                    <BaseTextField
                                        label={t('inscription.FirstName')}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        name="firstName"
                                        onChange={handleChange}
                                        value={form.firstName}
                                        error={errors.firstName && true}
                                        helperText={errors.firstName && t(errors.firstName) }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BaseTextField
                                        label={t('inscription.LastName')}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        name="lastName"
                                        onChange={handleChange}
                                        value={form.lastName}
                                        error={errors.lastName && true}
                                        helperText={errors.lastName && t(errors.lastName) }
                                    />
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <MapAutoComplete valueAutoComplete={form.address} errorAutoComplete={errors.address && true} helperTextAutoComplete={errors.address && t(errors.address) } handleChangeAutoComplete={handleChangeAutoComplete}/>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <BaseTextField
                                        label={t('inscription.Local')}
                                        fullWidth
                                        variant="outlined"
                                        name="local"
                                        onChange={handleChange}
                                        value={form.local}
                                        error={errors.local && true}
                                        helperText={errors.local && t(errors.local) }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" variant="standard" >
                                        <FormLabel component="legend">{t('inscription.Selection')}</FormLabel>
                                        <FormGroup row={true}>
                                            <FormControlLabel
                                                key={1}
                                                control={
                                                    <Checkbox
                                                        checked={form.isEmail}
                                                        onChange={handleMultiCheckBox}
                                                        name="isEmail"
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                }
                                                label={t('inscription.IsEmail')}
                                            />
                                            <FormControlLabel
                                                key={2}
                                                control={
                                                    <Checkbox
                                                        checked={form.isSMS}
                                                        onChange={handleMultiCheckBox}
                                                        name="isSMS"
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                }
                                                label={t('inscription.isSMS')}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    {
                                        form.isEmail && (
                                            <Grid item xs={12} md={6}>
                                                <BaseTextField
                                                    label={t('inscription.Email')}
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    name="email"
                                                    onChange={handleChange}
                                                    value={form.email}
                                                    error={errors.email && true}
                                                    helperText={errors.email && t(errors.email) }
                                                />
                                            </Grid>
                                        )
                                    }
                                    {
                                        form.isSMS && (
                                            <Grid item xs={12} md={6}>
                                                <BaseTextField
                                                    label={t('inscription.CellPhone')}
                                                    fullWidth
                                                    required
                                                    onChange={handleChange}
                                                    name="cellPhone"
                                                    id="formatted-cellphone-input"
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    variant="outlined"
                                                    value={form.cellPhone}
                                                    error={errors.cellPhone && true}
                                                    helperText={errors.cellPhone && t(errors.cellPhone) }
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} lg={9}/>
                                <Grid item xs={12} lg={3}>
                                    <Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isProcessing} onClick={submitHandler}>
                                        {isProcessing && <CircularProgress color="secondary"  size={20} />}
                                        {!isProcessing && t('inscription.Save')}
                                    </Button>
                                </Grid>
                            </>
                        )
                    }

                </Grid>
            </Grid>
        </Container>
    );
}

export default Inscription;