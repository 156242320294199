import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { TranslateLink } from 'components';
import apiClient from 'services/apiClient';
//images
import loginImg from 'asset/images/loginImg.png';
import logo from 'asset/images/logo.png';
import { useAuthContext } from '../../../contexts/auth';
import {
	Box,
	Button,
	Container,
	Grid,
	InputAdornment,
	Link,
	Typography,
} from '@mui/material';
import { BaseTextField } from '../../textfield/StyledTextField';
import theme from '../../../theme';

const myStyles = {
	container: {
		margin: 'auto',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		minHeight: '100vh',
		padding: '100px 0',
	},
	leftSide: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: '20px',
		padding: '50px',
	},
	rightSide: {
		padding: '70px 0px 70px 24px',
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		},
	},
};

const initialState = { email: '', password: '' };

function Login({ message }) {
	const { user, setUser } = useAuthContext();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [form, setForm] = useState(initialState);
	const [errors, setErrors] = useState({});
	const handleShowPassword = () => setShowPassword(!showPassword);

	useEffect(() => {
		// if user is already logged in,
		if (user?.email) {
			navigate('/dashboard');
		}
	}, [user, navigate]);

	const submitHandler = async () => {
		setIsProcessing(true);
		setErrors((e) => ({ ...e, form: null }));

		const { data, error } = await apiClient.loginUser({
			email: form.email,
			password: form.password,
		});

		if (data) {
			setUser(data.user);
			apiClient.setToken(data.token);
		}
		if (error) {
			setErrors((e) => ({ ...e, form: error }));
		}

		setIsProcessing(false);
	};

	const handleChange = (event) => {
		if (event.target.name === 'email') {
			if (event.target.value.indexOf('@') === -1) {
				setErrors((e) => ({ ...e, email: 'app.ValidEmail' }));
			} else {
				setErrors((e) => ({ ...e, email: null, form: null }));
			}
		}
		setForm({ ...form, [event.target.name]: event.target.value });
	};

	return (
		<>
			<Container maxWidth='lg' sx={myStyles.container}>
				<Grid
					container
					alignItems='center'
					justifyContent='space-between'>
					<Grid item xs={12} md={6} sx={myStyles.leftSide}>
						<div>
							<div className='login__translate'>
								<TranslateLink />
							</div>
						</div>
						<div className='login__image'>
							<img src={loginImg} alt='ECGNotifs' width='250px' />
						</div>
					</Grid>
					<Grid item xs={12} md={6} sx={myStyles.rightSide}>
						<Box
							display='flex'
							justifyContent='flex-start'
							alignItems='flex-start'
							flexDirection='column'>
							<Typography variant='h2'>
								{t('auth.SignIn')}
							</Typography>
							<Typography gutterBottom>
								{t('auth.SignInSubtitle')}
							</Typography>
						</Box>
						{errors.form && (
							<span className='errorMsg'>{t(errors.form)}</span>
						)}
						{message && (
							<span className='errorMsg'>{t(message)}</span>
						)}
						<BaseTextField
							label={t('auth.Email')}
							required
							fullWidth
							autoFocus
							variant='outlined'
							name='email'
							onChange={handleChange}
							value={form.email}
							error={errors.email && true}
							helperText={errors.email && t(errors.email)}
						/>
						<BaseTextField
							label={t('auth.Password')}
							fullWidth
							required
							type={showPassword ? 'text' : 'password'}
							variant='outlined'
							name='password'
							onChange={handleChange}
							value={form.password}
							error={errors.password && true}
							helperText={errors.password && t(errors.password)}
							InputProps={{
								endAdornment: (
									<InputAdornment
										onClick={handleShowPassword}
										className='showPassword'
										position='end'>
										{showPassword ? (
											<FontAwesomeIcon icon={faEye} />
										) : (
											<FontAwesomeIcon
												icon={faEyeSlash}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
						<Button
							fullWidth
							variant='contained'
							size='large'
							type='submit'
							disabled={isProcessing || errors.email}
							onClick={submitHandler}>
							{isProcessing ? t('app.Loading') : t('auth.SignIn')}
						</Button>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default Login;
