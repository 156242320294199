import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';

function Title(props) {
	const {t} = useTranslation();

	return (
		<>
			<Typography variant="h2">{t(props.title)}</Typography>
			{props?.subtitle && <Typography variant="h6" gutterBottom>
					{t(props.subtitle)}
				</Typography>
			}
		</>
	);
}

export default Title;