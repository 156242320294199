import {createTheme} from "@mui/material/styles";
import {frFR,enUS} from '@mui/material/locale';

let theme = createTheme({
	palette: {
		primary: {
			main: '#6aab2e',
			dark: '#7b7b7b',
			light: '#cccccc'
		},
		secondary: {
			main: '#0e71b8',
			dark: '#555555'
		},
	},
	frFR,
	enUS
})

theme = createTheme(theme, {
	typography:{
		h1:{
			[theme.breakpoints.down('md')]: {
				fontSize: '1rem'
			},
		}
	},
	components: {
		Mui:{
			styleOverrides:{
				focuses: {
					borderColor:'Black',
				}
			}
		},
		MuiButton: {
			styleOverrides:{
				root: {
					backgroundColor: theme.palette.primary.main,
				},
				containedPrimary: {
					"&:hover": {
						backgroundColor: theme.palette.secondary.main
					}
				},
				containedSecondary: {
					backgroundColor: theme.palette.secondary.dark,
					"&:hover": {
						backgroundColor: theme.palette.secondary.light,
					}
				},
			},
		},
		MuiLink:{
			styleOverrides: {
				root: {
					cursor: 'pointer'
				},
				underlineHover: {
					"&:hover": {
						color: '#abb4b7'
					}
				}
			}
		},
		MuiTextField:{
			styleOverrides:{
				root:{
					margin: theme.spacing(2),
					marginLeft: 0,
				},
			}
		},
	},
})

export default theme