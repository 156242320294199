import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import throttle from 'lodash/throttle';
import {Autocomplete, Box, Grid, Typography} from '@mui/material';
import {BaseTextField} from './StyledTextField';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';

const autocompleteService = { current: null };

function MapAutoComplete({valueAutoComplete, errorAutoComplete, helperTextAutoComplete, handleChangeAutoComplete}) {
	const {t} = useTranslation();
	const [value, setValue] = useState(valueAutoComplete);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);

	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 200),
		[]
	);

	useEffect(() => {
		let active = true;
		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}
		if (inputValue === "") {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};

	}, [value, inputValue, fetch])

	return (
		<Autocomplete
			id="google-map-demo"
			getOptionLabel={(option) =>
				typeof option === "string" ? option : option.description
			}
			filterOptions={(x) => x}
			options={options}
			noOptionsText={t('inscription.EnterAddress')}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
				handleChangeAutoComplete(newValue)
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(params) => (
				<BaseTextField {...params} label={t('inscription.Address')} fullWidth required error={errorAutoComplete} helperText={helperTextAutoComplete} />
			)}
			renderOption={(props, option) => {
				const matches =
					option.structured_formatting.main_text_matched_substrings;
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match) => [match.offset, match.offset + match.length])
				);

				return (
					<li {...props}>
						<Grid container alignItems="center">
							<Grid item>
								<Box
									component={LocationOnIcon}
									sx={{ color: "text.secondary", mr: 2 }}
								/>
							</Grid>
							<Grid item xs>
								{parts.map((part, index) => (
									<span
										key={index}
										style={{
											fontWeight: part.highlight ? 700 : 400
										}}
									>
                                        {part.text}
                                    </span>
								))}

								<Typography variant="body2" color="text.secondary">
									{option.structured_formatting.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
		/>
	);
}

export default MapAutoComplete;