import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid} from '@mui/material';
import {RoadCard,RoadCardList, Title, ToastMsg} from 'components';
import apiClient from '../../../services/apiClient';
import Swal from "sweetalert2";

function Road(props) {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {id} = useParams()
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false);

	const [routes, setRoutes] = useState([])
	const [notificationSend, setNotificationSend] = useState(false)

	useEffect(() => {
		const fetchAllRoutes = async() => {
			setIsFetching(true)
			const {data, error} = await apiClient.fetchAllRoutes()
			if (data){
				setRoutes(data.roads)
			}
			if (error){
				setErrors({msg: error})
			}
			setIsFetching(false)

		}
		fetchAllRoutes()
	}, []);

	const handleRoadsSelection = async (idRoad) => {
		setIsFetching(true)
		const routeNameSend = routes.find(r => r.id === idRoad).name
		const {data, error} = await apiClient.sendNotifications(id, idRoad)
		if (data) {
			Swal.fire({
				title: t('roads.NotificationsSendTitle'),
				html: t('roads.NotificationsSendDescription', {routeName: routeNameSend}),
				icon: 'success',
				timer: 5000,
				timerProgressBar: true,
			}).then((result) => {
				const url = '/'
				navigate(url)
			})
		}
		if (error) {
			setErrors({msg: error})
		}
		setIsFetching(false)
	}

	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg &&
				<ToastMsg open={true} type="error" message={t('apps.Error')}/>}
			{notificationSend &&
				<ToastMsg open={true} type="success" message={t('apps.SuccessNotification')}/>}
			{!isFetching && (
				<>
					<Grid container>
						<Grid item xs={12}>
							<Title title='roads.Title' subtitle=''/>
						</Grid>
					</Grid>
					<Grid container alignItems="center" justifyContent="space-between" spacing={3} sx={{paddingTop:2}}>
						<Grid item xs={10} md={4}>
							<RoadCard onClick={() =>handleRoadsSelection(0)} title={t('roads.All')} id={0} />
						</Grid>
						<RoadCardList contents={routes} onSelectionRoad={handleRoadsSelection} />
					</Grid>
				</>
			)}
		</>
	);
}

export default Road;