import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardActionArea} from '@mui/material';
import theme from '../../theme';

const myStyles = {
	card:{
		textAlign: 'center',
		color:'white',
		backgroundColor: theme.palette.primary.main,
		'& h4': {
			fontSize: '1.3rem'
		}
	},
	cardAction:{
		p:5,
		'&:hover':{
			'& .MuiCardActionArea-focusHighlight': {
				opacity: 0.75
			},
		},
		'& .MuiCardActionArea-focusHighlight': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
};

function NotificationCard({id, onClick, title}) {
	const {t} = useTranslation();

	function onClickCard(id){
		onClick(id)
	}

	return (
		<>
			<Card onClick={() => onClickCard(id)} sx={myStyles.card} >
				<CardActionArea sx={myStyles.cardAction} >
					<h4>{t(title)}</h4>
				</CardActionArea>
			</Card>
		</>
	);
}

export default NotificationCard;