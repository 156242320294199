import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid} from '@mui/material';
import {NotificationCard} from 'components';



function NotificationCardList({contents, onSelectionNotification}) {
	const {t} = useTranslation();
	return contents.map((notification, i) => {
		const {id, description} = notification
		return (
			<Grid item xs={10} md={4} key={i}>
				<NotificationCard onClick={onSelectionNotification} title={description} id={id} />
			</Grid>
		)
	})
}

export default NotificationCardList;