import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import EditRoadIcon from "@mui/icons-material/EditRoad";
import apiClient from "../../../services/apiClient";
import {adaptV4Theme, CircularProgress} from "@mui/material";
import {createTheme, StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import 'moment/locale/fr';
import moment from "moment";


const theme = createTheme(adaptV4Theme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
}))

function Logs(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [isFetching, setIsFetching] = useState(true)
    const [logs, setLogs] = useState([]);

    const [responsive, setResponsive] = useState("simple");
    const [tableBodyHeight, setTableBodyHeight] = useState("100%");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");


    const columns = [
        {
            name: "date",
            label: "Date Envoie",
            options: {
                filter: true,
                sort: true,
                customBodyRender: value =>
                    moment(value).format("LLL")
            }
        },
        {
            name: "road",
            label: "Route",
        },
        {
            name: "notification",
            label: "Notification envoyée",
        }
    ];

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive,
        download: false,
        print: false,

        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        rowsPerPage:100,
        tableBodyHeight,
        tableBodyMaxHeight
    };

    useEffect(() => {
        setIsFetching(true);
        apiClient.fetAllLogs()
            .then(response => {
                setLogs(response.data);
            })
            .catch(error => {
                setErrors({msg: error.response?.data});
            })
        setIsFetching(false);
    }, []);

    return (
        <>
            {isFetching && <CircularProgress color="secondary" size={40}/>}
            {
                !isFetching &&
                <>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <MUIDataTable
                                title={t('logs.Title')}
                                data={logs}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </>

            }
        </>
    );
}

export default Logs;