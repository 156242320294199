import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import {
	Clients,
	Dashboard, Inscription,
	Login, Logs,
	ProtectedRoute,
	Road
} from 'components';
import './asset/css/App.css';
import { AuthContextProvider } from 'contexts/auth';

export default function AppContainer() {
	return (
		<AuthContextProvider>
			<App />
		</AuthContextProvider>
	);
}

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route exact path='/' element={<Login />} />
					<Route exact path='/inscription' element={<Inscription />} />
					<Route
						exact
						path='/dashboard'
						element={<ProtectedRoute element={<Dashboard />} />}
					/>
					<Route
						exact
						path='/clients'
						element={<ProtectedRoute element={<Clients />} />}
					/>
					<Route
						exact
						path='/route/:id'
						element={<ProtectedRoute element={<Road />} />}
					/>
					<Route
						exact
						path='/routes'
						element={<ProtectedRoute element={<Dashboard />} />}
					/>
					<Route
						exact
						path='/logs'
						element={<ProtectedRoute element={<Logs />} />}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}
