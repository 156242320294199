import React, {useEffect, useState} from 'react';
import {adaptV4Theme, CircularProgress} from "@mui/material";
import {useTranslation} from "react-i18next";
import MUIDataTable from "mui-datatables";
import apiClient from "../../../services/apiClient";
import {ThemeProvider, StyledEngineProvider, createTheme} from '@mui/material/styles';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import {EditRoadModal, ToastMsg} from "../../index";
import DeleteIcon from '@mui/icons-material/Delete';
const theme = createTheme(adaptV4Theme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
}))

function Clients(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [isFetching, setIsFetching] = useState(true)
    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState(false);
    const [client, setClient] = useState({});
    const [roads, setRoads] = useState([]);
    const [successEdit, setSuccessEdit] = useState(false);

    const [responsive, setResponsive] = useState("simple");
    const [tableBodyHeight, setTableBodyHeight] = useState("100%");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");

    const columns = [
        {
            name: "firstName",
            label: "Prénom",
        },
        {
            name: "lastName",
            label: "Nom",
        },
        {
            name: "address",
            label: "Adresse",
        },
        {
            name: "local",
            label: "Appt.",
        },
        {
            name: "email",
            label: "Courriel",
        },
        {
            name: "mainPhone",
            label: "Phone",
        },
        {
            name: "routeName",
            label: "Route",
        },
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <EditRoadIcon onClick={() => handleOpen(dataIndex)}/>
                    );
                }
            }
        },
        {
            name: "Supprimer",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <DeleteIcon onClick={() => handleDelete(dataIndex)} sx={{color:'red', textAlign:'center', cursor:'pointer'}}/>
                    );
                }
            }
        },
    ];
    const options = {
        filter: true,
        filterType: "dropdown",
        responsive,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        rowsPerPage:100,
        tableBodyHeight,
        tableBodyMaxHeight
    };

    useEffect(() => {
        setIsFetching(true);
        apiClient.fetAllClients()
            .then(response => {
                setClients(response.data);
                apiClient.fetchAllRoutes()
                    .then(r => {
                        setRoads(r.data.roads);
                        setIsFetching(false);
                    })
                    .catch(error => {
                        setIsFetching(false);
                        setErrors(error.response?.data);
                    })
            })
            .catch(error => {
                setErrors({msg: error.response?.data});
                setIsFetching(false);
            })
    }, [])

    const handleOpen = (item) => {
        setClient(clients[item]);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setClient({});
            setOpen(false);
        }
    };

    const handleEditRoadCompleted = () => {
        setOpen(false);
        setSuccessEdit(true);
    }

    const handleDelete = () => {
        setOpen(false);
        setSuccessEdit(true);
    }

    return (
        <>
            {isFetching && <CircularProgress color="secondary" size={40}/>}
            {successEdit &&
                <ToastMsg open={true} type="success" message={t('clients.SuccessEditRoad')}/>}
            {

                !isFetching &&
                    <>
                        <EditRoadModal open={open} handleClose={handleClose} handleEditRoadCompleted={handleEditRoadCompleted} client={client} roads={roads}/>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <MUIDataTable
                                    title={t('clients.Title')}
                                    data={clients}
                                    columns={columns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </>

            }
        </>
    );
}

export default Clients;