import React, { useState } from 'react';
import { useAuthContext } from 'contexts/auth';
import { Header, Login } from 'components';
import { useTranslation } from 'react-i18next';
import {Alert, Box, Container} from '@mui/material';


export default function ProtectedRoute({ element }) {
	const { t } = useTranslation();
	const { user, initialized } = useAuthContext();

	if (!initialized) return null;

	if (initialized && !user?.email) {
		return <Login message='app.MustBeAuthenticated' />;
	}

	return (
		<>
			<Header/>
			<Container maxWidth='lg'>
				<Box sx={{ display: 'flex' }}>
						<div className="element-container">
							{element}
						</div>

				</Box>
			</Container>
		</>
	);
}
